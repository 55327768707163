import React, { useState, useEffect } from 'react'
import sessionHandler from '../../../utilities/SessionHandler'
import sessionUtils from '../../../utilities/SessionHandler'

import { useNavigate, useParams } from 'react-router-dom'

import Navbar from '../../../components/Private/Navbar'
import ListSection from '../../../components/Private/ListSection'
import ModalComponent from '../../../components/Private/ModalComponent'

import companyService from '../../../services/companies'
import businessRoleService from '../../../services/businessroles'
import companyUserService from '../../../services/companyUsers'

export default function List() {

  const navigateTo = useNavigate()

  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorModalTitle, setErrorModalTitle] = useState('Error')
  const [errorMessage, setErrorMessage] = useState('')

  const [showSignOutModal, setShowSignOutModal] = useState(false)
  const [signOutModalTitle, setSignOutModalTitle] = useState('Sesión Cerrada')
  const [showAddModal, setShowAddModal] = useState(false)
  const [addModalTitle, setAddModalTitle] = useState('Crear usuario')
  const [showEditModal, setShowEditModal] = useState(false)
  const [editModalTitle, setEditModalTitle] = useState('Editar usuario')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteModalTitle, setDeleteModalTitle] = useState('Borrar usuario')
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [transferModalTitle, setTransferModalTitle] = useState('Transferir administración')

  const [companyData, setCompanyData] = useState()
  const [tabList, setTabList] = useState([])
  const [businessRoleList, setBusinessRoleList] = useState([])
  const [listData, setListData] = useState(null)

  const [selectedItemId, setSelectedItemId] = useState(null)

  const credentials = sessionHandler.getParsedCredentials();

  const formDefaultData = {
    company_id: credentials.company_id,
    businessRole_id: 2,
    companyUserFullName: '',
    companyUserDNI: '',
    companyUserPhone: '',
    companyUserEmail: '',
    companyUserPassword: '',
  }

  const [formData, setFormData] = useState(formDefaultData)

  

  const handleFieldChange = (event) => {
    setFormData(
      {
        ...formData,
        [event.target.name]: event.target.value
      }
    )
  }

  const handleSignOut = () => {
    setTimeout(() => {
      sessionUtils.removeCredentials()
      navigateTo('/')
    }, 300);
  }

  useEffect(() => {
    let tabs = [];
    tabs.push(
      {
        title: 'Administrador',
        active: false,
        filter: [
          {
            criteria: 'businessRole',
            operator: 'equals',
            value: "Administrador"
          }
        ]
      })
    tabs.push({
      title: 'Agente',
      active: false,
      filter: [
        {
          criteria: 'businessRole',
          operator: 'equals',
          value: "Agente"
        }
      ]
    })
    setTabList(tabs)
  }, [])

  const getFormDOM = () => {
    return <>
      <div className='mb-3'>
        <label htmlFor='companyUserFullName' className='form-label'>Nombre completo del usuario</label>
        <input
          name='companyUserFullName'
          id='companyUserFullName'
          type='text'
          className='form-control'
          placeholder='Nombre completo'
          value={formData.companyUserFullName}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.businessRole_id) >= parseInt(formData.businessRole_id)
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='companyUserEmail' className='form-label'>Correo electrónico</label>
        <input
          name='companyUserEmail'
          id='companyUserEmail'
          type='email'
          className='form-control'
          placeholder='Correo electrónico'
          value={formData.companyUserEmail}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.businessRole_id) >= parseInt(formData.businessRole_id)
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='companyUserPassword' className='form-label'>Contraseña</label>
        <input
          name='companyUserPassword'
          id='companyUserPassword'
          type='password'
          className='form-control'
          placeholder='Contraseña'
          value={formData.companyUserPassword}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.businessRole_id) >= parseInt(formData.businessRole_id)
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='companyUserDNI' className='form-label'>RUT</label>
        <input
          name='companyUserDNI'
          id='companyUserDNI'
          type='text'
          className='form-control'
          placeholder='RUT'
          value={formData.companyUserDNI}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.businessRole_id) >= parseInt(formData.businessRole_id)
          }
        />
      </div>
      <div className='mb-3'>
        <label htmlFor='companyUserPhone' className='form-label'>Teléfono</label>
        <input
          name='companyUserPhone'
          id='companyUserPhone'
          type='text'
          className='form-control'
          placeholder='Teléfono'
          value={formData.companyUserPhone}
          onChange={
            (event) => {
              handleFieldChange(event)
            }
          }
          disabled={
            parseInt(credentials.businessRole_id) >= parseInt(formData.businessRole_id)
          }
        />
      </div>
      {
        parseInt(credentials.businessRole_id) < parseInt(formData.businessRole_id) ?
        <div className='mb-3'>
          <label htmlFor='businessRole_id' className='form-label'>Rol</label>
          <select
            name='businessRole_id'
            id='businessRole_id'
            className='form-select'
            value={formData.businessRole_id}
            onChange={
              (event) => {
                handleFieldChange(event)
              }
            }
          >
            {
              businessRoleList
                .filter(
                  (item) => {
                    return parseInt(credentials.businessRole_id) < parseInt(item.id)
                  }
                )
                .map(
                  (item) => {
                    return <option
                      key={item.id}
                      value={item.id}
                    >
                      {item.businessRoleName}
                    </option>
                  }
                )
            }
          </select>
          </div>
          :
          <div className='mb-3'>
            <input type='hidden' name='businessRole_id' value={formData.businessRole_id} />
            <p>
              <strong>Rol:</strong> {
                businessRoleList.find(
                  (item) => {
                    return item.id === formData.businessRole_id
                  }
                )?.businessRoleName
              }
            </p>
          </div>
      }

    </>
  }

  const handleDataCreate = async () => {
    try {
      await companyUserService.create(formData)
      await handleDataLoad()
      setShowAddModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleDataUpdate = async (itemId) => {
    try {
      await companyUserService.update(itemId, formData)
      await handleDataLoad()
      setShowEditModal(false)
      setSelectedItemId(null)
    } catch (error) {
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }


  const handleDataDelete = async (itemId) => {
    await companyUserService.remove(itemId)
    await handleDataLoad()
  }

  const handleDataTransfer = async (itemId) => {
    try {
      const formData = {
        source_id: credentials.id,
        target_id: itemId,
      }
      const result = await companyUserService.transferRole(formData)
      setShowSignOutModal(true)
      setShowTransferModal(false)
      setSelectedItemId(null)
    } catch (error) {
      console.log(error)
      setErrorMessage(error.response.data.messages.error)
      setShowErrorModal(true)
    }
  }

  const handleBusinessRoleListDataLoad = async () => {
    const result = await businessRoleService.list()
    setBusinessRoleList(result)
  }

  const handleDataLoad = async () => {
    const company_id = credentials.company_id
    await handleBusinessRoleListDataLoad()
    const companyResult = await companyService.get(company_id)
    const result = await companyUserService.byCompany(company_id);
    setListData(result)
    setCompanyData(companyResult)
  }

  useEffect(() => {
    handleDataLoad()
  }, [])

  useEffect(() => {
    if (listData?.length > 0) {
    }
  }, [listData])


  return (
    <>
      <Navbar companyUser />
      {
        listData != null &&
        <ListSection
          title={`Usuarios`}
          hasSearch={false}
          hasYear={false}
          tabs={
            [
              ...tabList
            ]
          }
          pagination={
            {
              itemsPerPage: 10,
              maxPagesInPagination: 5,
              currentPage: 1,
              prevPage: 'Anterior',
              nextPage: 'Siguiente',
              lastPage: 'Última',
            }
          }
          backRoute={
            {
              title: 'Volver',
              onClick: () => {
                navigateTo('/home')
              }
            }
          }
          actions={
            {
              title: 'Crear usuario',
              onClick: () => {
                setFormData(formDefaultData)
                setShowAddModal(true)
              }
            }
          }
          columns={
            [
              {
                title: 'Nombre completo',
                field: 'companyUserFullName',
                type: 'text',
              },
              {
                title: 'Correo electrónico',
                field: 'companyUserEmail',
                type: 'text',
              },
              {
                title: 'RUT',
                field: 'companyUserDNI',
                type: 'text',
              },
              {
                title: 'Rol',
                field: 'businessRole',
                type: 'text',
              },
              {
                title: 'Acciones',
                field: 'actions',
                type: 'actions',
              },
            ]
          }
          rows={
            [
              ...listData.map((item, index) => {

                let actions = []

                actions.push(
                  {
                    className: 'btn',
                    icon: 'bi bi-pencil',
                    tooltip: 'Editar',
                    onClick: () => {
                      setFormData({
                        companyUserFullName: item.companyUserFullName,
                        companyUserEmail: item.companyUserEmail,
                        companyUserPassword: '',
                        companyUserDNI: item.companyUserDNI,
                        companyUserPhone: item.companyUserPhone,
                        businessRole_id: item.businessRole_id,
                      })
                      setSelectedItemId(item.id)
                      setEditModalTitle('Editar a ' + item.companyUserFullName)
                      setShowEditModal(true)
                    }
                  }
                )
                if (parseInt(credentials.businessRole_id) < parseInt(item.businessRole_id)) {
                  actions.push(
                    {
                      className: 'btn',
                      icon: 'bi bi-file-person',
                      tooltip: 'Transferir Administración',
                      onClick: () => {
                        setSelectedItemId(item.id)
                        setTransferModalTitle('Transferir Administración a ' + item.companyUserFullName)
                        setShowTransferModal(true)
                      }
                    }
                  )
                  actions.push(
                    {
                      className: 'btn',
                      icon: 'bi bi-trash',
                      tooltip: 'Eliminar',
                      onClick: () => {
                        setSelectedItemId(item.id)
                        setDeleteModalTitle('Eliminar a ' + item.companyUserFullName)
                        setShowDeleteModal(true)
                      }
                    }
                  )
                }

                return {
                  companyUserFullName: item.companyUserFullName,
                  companyUserEmail: item.companyUserEmail,
                  companyUserDNI: item.companyUserDNI,
                  businessRole: businessRoleList?.find(
                    (businessRole) => {
                      return businessRole.id === item.businessRole_id
                    }
                  )?.businessRoleName,
                  actions: [...actions],
                }
              })
            ]
          }
        />
      }
      <ModalComponent
        id='createModal'
        title={addModalTitle}
        show={showAddModal}
        setShow={setShowAddModal}
      >
        {getFormDOM()}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowAddModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataCreate()
            }}
          >
            Crear
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='editModal'
        title={editModalTitle}
        show={showEditModal}
        setShow={setShowEditModal}
      >
        {getFormDOM()}
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowEditModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataUpdate(selectedItemId)
            }}
          >
            Guardar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='deleteModal'
        title={deleteModalTitle}
        show={showDeleteModal}
        setShow={setShowDeleteModal}
      >
        <p>¿Está seguro que desea borrar este usuario?</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => {
              handleDataDelete(selectedItemId)
              setShowDeleteModal(false)
              setSelectedItemId(null)
            }}
          >
            Borrar
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='transferModal'
        title={transferModalTitle}
        show={showTransferModal}
        setShow={setShowTransferModal}
      >
        <p>¿Está seguro que deseas transferir tu rol administrativo?</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowTransferModal(false)
              setSelectedItemId(null)
            }}
          >
            Cancelar
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              handleDataTransfer(selectedItemId)
              setShowTransferModal(false)
              setSelectedItemId(null)
            }}
          >
            Transferir
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='errorModal'
        title={errorModalTitle}
        show={showErrorModal}
        setShow={setShowErrorModal}
        headerClass='bg-danger text-white'
        modalSize='modal-lg'
      >
        <p>{errorMessage}</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={() => {
              setShowErrorModal(false)
              setSelectedItemId(null)
            }}
          >
            Ok
          </button>
        </div>
      </ModalComponent>
      <ModalComponent
        id='signOutModal'
        title={signOutModalTitle}
        show={showSignOutModal}
        setShow={setShowSignOutModal}
        headerClass='bg-primary text-white'
        modalSize='modal-lg'
      >
        <p>Su sesión ha sido finalizada para almacenar los cambios</p>
        <div className='d-flex justify-content-between mt-4'>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={async () => {
              setShowSignOutModal(false)
              setSelectedItemId(null)
              await handleSignOut()
            }}
          >
            Ok
          </button>
        </div>
      </ModalComponent>
    </>
  )
}
