import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import sessionUtils from '../../utilities/SessionHandler'

import Navbar from '../../components/Private/Navbar'
import StatisticCard from '../../components/Private/Card/DashboardStatistics'
import NavigationCard from '../../components/Private/Card/DashboardNavigation'

import contestService from '../../services/contests'
import companyUserService from '../../services/companyUsers'
import businessRoleService from '../../services/businessroles'
import applicationService from '../../services/applications'

export default function Home() {

  const navigateTo = useNavigate()
  const [selectedContest, setSelectedContest] = useState(null)
  const contestRef = React.useRef(null);
  const [contests, setContests] = useState([])
  const [companyUser, setCompanyUser] = useState(null)
  const [companyUserRole, setCompanyUserRole] = useState(null)
  const [applications, setApplications] = useState(
    {
      por_revisar: 0,
      pre_seleccionadas: 0,
      finalizadas: 0,
      destacadas: 0
    }
  )

  const [loading, setLoading] = useState(null)

  let credentials = sessionUtils.getParsedCredentials()

  const handleContestsLoad = async () => {
    const result = await contestService.list()
    let contestItems;

    //order the results by item.id, invert it, and then set it to the contests state

    contestItems = Object.entries(result).map(([key, value]) => value)
    contestItems = contestItems.sort((a, b) => a.id - b.id).reverse()


    setContests(contestItems)
  }

  const handleUserLoad = async () => {
    const result = await companyUserService.get(credentials.id)
    setCompanyUser(result)
  }

  const handleRoleLoad = async () => {
    const result = await businessRoleService.get(credentials.businessRole_id)
    setCompanyUserRole(result)
  }


  const handleApplicationsLoad = async () => {

    const payload = [
      sessionStorage.getItem('selectedContest') /* Current contest */,
      credentials.company_id /* Company */
    ];

    const result = await applicationService.byContestAndCompany(
      payload
    );

    console.log(result);

    let por_revisar = 0
    let pre_seleccionadas = 0
    let finalizadas = 0
    let destacadas = 0

    const XResult = Object.entries(result).map(([key, value]) => value)

    XResult.forEach((application) => {
      if (application.applicationStatusAfterDraft === 'En revisión') {
        por_revisar++
      }
      else
        if (application.applicationStatusPreselection === 'En revisión') {
          pre_seleccionadas++
        }
        else
          if (application.applicationStatusFinalist === 'En revisión') {
            finalizadas++
          }
          else
            if (application.applicationStatusFinal === 'Destacada') {
              destacadas++
            }
    })

    setApplications({
      por_revisar,
      pre_seleccionadas,
      finalizadas,
      destacadas
    })

    setLoading(false)

  }

  const handleContestChange = () => {
    console.log("on change:" + contestRef.current.value)
    setSelectedContest(contestRef.current.value)
    sessionStorage.setItem('selectedContest', contestRef.current.value)
    setLoading(true)
    handleApplicationsLoad()
  }

  useEffect(() => {
    handleContestsLoad()
    handleUserLoad()
    handleRoleLoad()
  }, [])

  useEffect(() => {
    if (contests && contests.length > 0) {
      if (selectedContest === null) {
        if (sessionStorage.getItem('selectedContest') === null) {
          setSelectedContest(contests[0].id)
          sessionStorage.setItem('selectedContest', contests[0].id)
        } else {
          setSelectedContest(sessionStorage.getItem('selectedContest'))
        }
      }
      if (contestRef.current !== null) {
        contestRef.current.value = sessionStorage.getItem('selectedContest')
      }
      setLoading(true)
      handleApplicationsLoad()
    }
  }, [contests])

  return (
    <>
      <Navbar companyUser />
      <main className="theme-light">
        <div className="container">
          {
            contests && contests.length > 0 && (
              <div className="row mb-4">
                <div className="col-12 col-lg-4">
                  <select className="form-select" aria-label="contestFilter" ref={contestRef} onChange={handleContestChange}>
                    {
                      contests.map((contest, key) => {
                        let fullDate = contest.created_at; //format: YYYY-MM-DD HH:MM:SS
                        //extract Year using React or JavaScript
                        let year = fullDate.date.substring(0, 4);
                        return (
                          <option key={key} value={contest.id}>{contest.contestName} ({year})</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
            )
          }
        </div>
        <div className="container">
          {
            companyUserRole && [1, 2].includes(parseInt(companyUserRole.id)) && (
              <>
                {
                  [1].includes(parseInt(companyUserRole.id)) && (
                    <div className="row row-cols-lg-4 row-cols-2 g-3 mb-lg-5 mb-4 text-center">
                      <div className="col">
                        <StatisticCard
                          classType='status-send'
                          title={applications.por_revisar}
                          text='Revisar'
                          loading={loading}
                        />
                      </div>
                      <div className="col">
                        <StatisticCard
                          classType='status-draft'
                          title={applications.pre_seleccionadas}
                          text='Pre-seleccionadas'
                          loading={loading}
                        />
                      </div>
                      <div className="col">
                        <StatisticCard
                          classType='status-approved'
                          title={applications.finalizadas}
                          text='Finalizadas'
                          loading={loading}
                        />
                      </div>
                      <div className="col">
                        <StatisticCard
                          classType='status-featured'
                          title={applications.destacadas}
                          text='Destacadas'
                          loading={loading}
                        />
                      </div>
                    </div>
                  )
                }
                
                <div className="row row-cols-lg-3 row-cols-1 g-4 mb-5">
                  {
                    [1].includes(parseInt(companyUserRole.id)) && (
                      <div className="col">
                        <NavigationCard
                          icon={<i className="bi bi-people-fill card-icon"></i>}
                          title='Administrador de Usuarios'
                          route='/companyusers'
                        />
                      </div>
                    )
                  }
                  <div className="col">
                    <NavigationCard
                      icon={<i className="bi bi-folder-fill card-icon"></i>}
                      title='Postulaciones'
                      route='/applications'
                    />
                  </div>
                  <div className="col">
                    <NavigationCard
                      icon={<i className="bi bi-envelope-at-fill card-icon"></i>}
                      title='¿Necesitas ayuda?'
                      route='/tickets'
                      buttonValue='Consultar'
                    />
                  </div>
                </div>
              </>
            )
          }
        </div>
      </main>
    </>
  )
}
